import React, { useState } from 'react'
import "./SingaleCheckBox.css"
import { IsEmpty } from '../../config/appConstant';

export default function SingaleCheckBox(props) {
    const { type, label, name, checked, id, onChange, defaultValue, isDisabled, IslabelShow, label2, IsInput, InputValue, setcheckInput, checkInput } = props
    const [isChecked, setIsChecked] = useState(defaultValue);
    const handleChackboxInput = (e) => {
        const { name, value } = e.target;
        setcheckInput(value)
    }
    return (
        <div className='custom-checkbox'>
            {IsEmpty(IslabelShow) &&
                <input
                    disabled={isDisabled ? true : false}
                    id={id}
                    type={type}
                    name={name}
                    defaultChecked={checked}
                    checked={defaultValue == true ? true : checked}
                    onChange={onChange}
                />}
            <label htmlFor={`${id}`} style={
                checked
                    ? !IsEmpty(IslabelShow) ? { marginLeft: '30px', fontWeight: 600, display: 'flex', alignItems: 'center' } : { fontWeight: 600, display: 'flex', alignItems: 'center' }
                    : !IsEmpty(IslabelShow) ? { marginLeft: '30px', fontWeight: 400, display: 'flex', alignItems: 'center' } : { fontWeight: 400, display: 'flex', alignItems: 'center' }
            }>{label}&nbsp;{IsInput && <input className='singlecheckbox-input' name="checkinput" value={checkInput} onChange={(e) => handleChackboxInput(e)} />}&nbsp;{label2}</label>
        </div>
    )
}