import React, { useEffect, useRef, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { Circle, GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps";
import { compose, withProps } from "recompose";
import { URLs } from "../../API/URLs";
import { DefaultLatLng } from "../../config/appConstant";
import { BaseColor, Images } from "../../config";

const MyMapComponent = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${URLs.googlekey}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const { isMarkerShown, isRadiusShow, locationDataSet } = props;
  const mapRef = useRef(null);
  const [zoom, setZoom] = useState(4);

  const mapStyles = [
    {
      featureType: "poi",
      stylers: [{ visibility: "off" }]
    },
    {
      featureType: "transit",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }]
    }
  ];

  const options = {
    mapTypeControl: false,
    styles: mapStyles
  };

  useEffect(() => {
    if (locationDataSet.length > 0 && mapRef.current) {
      // Filter only the verified locations
      const verifiedLocations = locationDataSet.filter(location => location.IsLocationVerified);

      if (verifiedLocations.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();

        verifiedLocations.forEach(location => {
          bounds.extend(new window.google.maps.LatLng(location.lat, location.lng));
        });

        if (isRadiusShow && verifiedLocations.length === 1) {
          const { lat, lng, radius } = verifiedLocations[0];
          const circleRadius = radius && radius > 0 ? radius : 16093; // 10 miles in meters if radius is 0 or not specified
          const circleBounds = new window.google.maps.Circle({
            center: { lat, lng },
            radius: circleRadius
          }).getBounds();

          mapRef.current.fitBounds(circleBounds);
          const mapElement = mapRef.current.getDiv();
          const { width, height } = mapElement.getBoundingClientRect();
          const calculatedZoom = calculateZoomLevel(circleRadius, width, height);

          setZoom(calculatedZoom);
        } else if (verifiedLocations.length === 1 && !isRadiusShow) {
          const { radius } = verifiedLocations[0];
          if (!radius || radius === 0) {
            const defaultRadius = 8000; // 10 miles in meters
            const calculatedZoom = calculateZoomLevel(defaultRadius, mapRef.current.getDiv().offsetWidth, mapRef.current.getDiv().offsetHeight);
            setZoom(calculatedZoom);
          } else {
            mapRef.current.fitBounds(bounds);
            setZoom(20); // Default zoom out level
          }
        } else {
          mapRef.current.fitBounds(bounds);
          setZoom(mapRef.current.getZoom());
        }
      }
    }
  }, [locationDataSet, isRadiusShow]);

  const calculateZoomLevel = (radius, mapWidth, mapHeight) => {
    const earthCircumference = 40075017;
    const radiusInMeters = radius * 2;

    let scaleFactor;
    if (radius < 5000) {
      scaleFactor = 256; // Base scale for smaller radii
    } else if (radius === 5000) {
      scaleFactor = 275; // Slightly larger scale for medium radii
    } else if (radius === 10000) {
      scaleFactor = 290; // Slightly larger scale for medium radii
    } else if (radius === 15000) {
      scaleFactor = 280; // Slightly larger scale for medium radii
    } else if (radius === 20000) {
      scaleFactor = 320; // Larger scale for larger radii
    } else if (radius === 0) {
      scaleFactor = 1; // Scale factor for very small radius
    } else {
      scaleFactor = 300; // Maximum scale for very large radii
    }

    const scaleWidth = radiusInMeters / mapWidth;
    const scaleHeight = radiusInMeters / mapHeight;
    const scale = Math.max(scaleWidth, scaleHeight) * scaleFactor;
    const zoomLevel = Math.log2(earthCircumference / scale);

    return Math.min(Math.max(Math.round(zoomLevel), 1), 20);
  };

  return (
    <GoogleMap
      key={JSON.stringify(locationDataSet)}  // Add key here to force re-render on locationDataSet change
      ref={mapRef}
      options={options}
      zoom={zoom}
      defaultCenter={
        locationDataSet.find(location => location.IsLocationVerified) || DefaultLatLng
      }
    >
      {isMarkerShown && locationDataSet
        .filter(item => item.IsLocationVerified)
        .map((item, index) => (
          <Fragment key={index}>
            <Marker
              icon={Images.locationPinIcon}
              position={{
                lat: item.lat,
                lng: item.lng
              }}
            />
            {isRadiusShow && (
              <Circle
                center={{
                  lat: item.lat,
                  lng: item.lng
                }}
                radius={parseInt(item.radius || 16093)}  // 10 miles as default radius if not specified
                visible={true}
                options={{ strokeColor: BaseColor.redColor }}
              />
            )}
          </Fragment>
        ))}
    </GoogleMap>
  );
});


MyMapComponent.propTypes = {
  isMarkerShown: PropTypes.bool,
  isRadiusShow: PropTypes.bool,
  locationRadius: PropTypes.number,
  zoom: PropTypes.number,
  currentCityCord: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  MapOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  locationDataSet: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
      radius: PropTypes.number,
      IsLocationVerified: PropTypes.bool.isRequired
    })
  ).isRequired
};

MyMapComponent.defaultProps = {
  currentCityCord: {},
  locationDataSet: [],
  isMarkerShown: false,
  isRadiusShow: false,
  MapOptions: { streetViewControl: false },
  locationRadius: 5 * 1000,
  zoom: 11
};

export default MyMapComponent;
