
import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { Modal, Text } from '../../components';
import { BaseColor } from '../../config';
import PropTypes from "prop-types";

function Loader(props) {
    const { IsTrasparent } = props
    return (
        <Modal show={true} IsTrasparent={IsTrasparent} IsCloseIcon={false}>
            <div style={{ width: '100wh', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                <CircularProgress size={50} style={{ color: BaseColor.loaderColor }} />
                {/* <Text header style={{ marginLeft: '20px' }}> Loading...</Text> */}
            </div >
        </Modal>
    )
}
Loader.propTypes = {
    IsTrasparent: PropTypes.bool,
};

Loader.defaultProps = {
    IsTrasparent: true,
};
export default Loader;

