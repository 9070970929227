/*eslint-disable eqeqeq*/
import React from 'react';

import 'jodit';
// import 'jodit/build/jodit.min.css';

import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { IsEmpty } from '../../config/appConstant';

const content = { "entityMap": {}, "blocks": [{ "key": "637gr", "text": "", "type": "unstyled", "depth": 0, "inlineStyleRanges": [], "entityRanges": [], "data": {} }] };

class EditorComponents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contentState: content,
            editorState: EditorState.createEmpty(),
        }
        this.setDomEditorRef = ref => this.domEditor = ref;
    }

    componentWillMount() {
        if (!IsEmpty(this.props.content)) {
            this.setState({
                editorState: EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                        convertFromHTML(this.props.content)
                    )
                )
            })
            const contentBlock = htmlToDraft(this.props.content)
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
            this.setState({
                editorState: EditorState.createWithContent(contentState)
            })
        }
    }

    onEditorStateChange = (editorState) => {
        this.props.setContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
        this.setState({ editorState, });
    };

    render() {
        return <Editor
            ref={this.setDomEditorRef}
            editorState={this.state.editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            handlePastedText={() => false}
            style={{ zIndex: '0' }}
            onEditorStateChange={this.onEditorStateChange.bind(this)}
            toolbar={
                {
                    options: ['history', 'fontFamily', 'fontSize', 'inline', 'colorPicker', 'textAlign', 'list', 'link'],
                    inline: {
                        inDropdown: false,
                        options: ['bold', 'italic', 'underline', 'strikethrough'],
                    },
                    fontSize: {
                        options: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 30, 36, 48, 60, 72, 96]
                    },
                    fontFamily: {
                        options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Verdana', 'Raleway']
                    },
                    // list: {
                    //     inDropdown: false,
                    //     options: ['ordered', 'unordered', 'indent', 'outdent'],
                    // },
                    textAlign: {
                        inDropdown: true,
                        options: ['left', 'center', 'right', 'justify'],
                    },
                    colorPicker: {
                        colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                            'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                            'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                            'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                            'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                            'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                    },
                    // history: {
                    //     options: ['undo', 'redo'],
                    // },
                }
            }
        />;
    }
}

export default EditorComponents;