import React, { useEffect, useState } from 'react';
import Styles from './PageSubTitleStyle';
import PropTypes from "prop-types";
import { IconButton, styled, Tooltip, withStyles } from '@material-ui/core';
import { Button, Text, TextField } from "../../components";
import { BaseColor, FontFamily, Images } from "../../config";
import './Pagesubtitle.css';
import Uploadfile from '../../components/Uploadfile/Uploadfile';
import { CustomTooltip, IsEmpty } from '../../config/appConstant';

const PageSubTitle = (props) => {
    const {
        IsSticky, offsetHeight,
        RightButtonText, LeftDataClick, isRightButton1, disabled, isRightButton2, RightButtonClick1,
        RightButtonClick2, RightButtonText1, RightButtonDisabled1, RightButtonText2, isCenterButtonShow, isCenterArrowButtonShow,
        isRightButtonShow, LeftData, CenterRightClick, RightButtonClick, CenterLeftClick, CenterContentText,
        isborderShow, isUploadButton, onUploadChange, Uploadaccept, RightTextfeild, isRightTextfeild, onChange,
        searchValue, cleardata,
    } = props;

    const [isStickySubTitle, setIsStickySubTitle] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > offsetHeight) {
                setIsStickySubTitle(true);
            } else {
                setIsStickySubTitle(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <div className={IsSticky && isStickySubTitle ? 'pageSubTitle-sticky' : ''}>
            <div style={isborderShow ? props.ViewArtworkpopup == 1 ? Styles.ViewArtworkpopupborderbotttom : Styles.pageSubtitleborderbotttom : Styles.pageSubtitleMain}>
                <div
                    style={
                        props.ViewArtworkpopup == 1
                            ? { ...Styles.ViewArtworkpopupleststartsection }
                            : (LeftData.length === 1 && !LeftData[0].ShowTutorial
                                ? { ...Styles.leststartsection }
                                : { ...Styles.startsection })
                    }
                    className="pagetitle-left-header"
                >
                    {LeftData.map((item, index) => {
                        return (
                            item.ShowTutorial && (
                                <div
                                    key={item.id}
                                    onClick={() => LeftDataClick(item, index)}
                                    style={item.cursorPointer ? { ...Styles.cursorPointer, ...Styles.flexSectionAlignment } : { ...Styles.flexSectionAlignment }}
                                >
                                    <CustomTooltip title={item.tooltip} arrow placement="bottom-start">
                                        <img style={index === 0 ? { ...Styles.iconsIndex } : { ...Styles.Icons }} src={item.icon} alt="edit" />
                                    </CustomTooltip>
                                    <Text grayColor subhead medium>{item.text}</Text>
                                    {item.isDrop && <div className="dropdown-toggle"></div>}
                                </div>
                            )
                        );
                    })}
                </div>

                {isCenterButtonShow && <div style={ props.ViewArtworkpopup == 1? { ...Styles.ViewArtworkpopupmiddlesection }: { ...Styles.middlesection }} className={props.ViewArtworkpopup == 1? 'viewartworkpopup-center-header':'pagetitle-center-header'}>
                    {isCenterArrowButtonShow ? <IconButton style={Styles.IconButtonPadding} onClick={CenterLeftClick}>
                        <div className='left-round-arrow'></div>
                        {/* {/ <img src={Images.round_button_left} alt="edit" / > /} */}
                    </IconButton> : <></>}

                    <Text title3 bold grayColor style={{ padding: '0 10px' }}>{CenterContentText}</Text>

                    {isCenterArrowButtonShow ? <IconButton style={Styles.IconButtonPadding} onClick={CenterRightClick}>
                        <div className='right-round-arrow'></div>
                        {/* {/ <img src={Images.round_button_left} alt="edit" / > /} */}
                    </IconButton> : <></>}

                    {/* {/ <IconButton style={Styles.IconButtonPadding} onClick={CenterRightClick}><img src={Images.RightArrowGray} alt="edit" / ></IconButton> /} */}
                </div>}

                <div style={{ ...Styles.endsection }} className='pagetitle-right-header'>
                    {isRightButtonShow && <>
                        {isRightButton1 && <div style={IsEmpty(LeftData) ? Styles.subtitlebutton1withPadding : Styles.subtitlebutton1}><Button disabled={RightButtonDisabled1} type={3} onClick={RightButtonClick1} round Medium title={RightButtonText1} boxShadow={false} /></div>}
                        {isRightTextfeild && <div style={Styles.subtitlebutton3}><TextField label={'Search Group'} name={'Search Group'} value={searchValue} onChange={onChange} title={RightTextfeild} handleClear={cleardata} /></div>}
                        {isRightButton2 && <div className={IsEmpty(LeftData) ? 'subtitlebutton2withPadding' : 'subtitlebutton2-margin'}><Button disabled={disabled} type={3}  onClick={RightButtonClick2} round Medium title={RightButtonText2} boxShadow={false} /></div>}
                        {isUploadButton && <div className='subtitlebutton2-margin' ><Uploadfile onChange={onUploadChange} round type={3} title="Upload" accept={Uploadaccept} /></div>}
                    </>}
                </div>
            </div>
        </div >
    );
}

PageSubTitle.propTypes = {
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    subTitle: PropTypes.string,
    title: PropTypes.string,
    CenterContentText: PropTypes.string,
    RightButtonText: PropTypes.string,
    isborderShow: PropTypes.bool,
    isCenterButtonShow: PropTypes.bool,
    isCenterArrowButtonShow: PropTypes.bool,
    isRightButtonShow: PropTypes.bool,
    isRightButton1: PropTypes.bool,
    RightButtonDisabled1: PropTypes.bool,
    isRightButton2: PropTypes.bool,
    IsSticky: PropTypes.bool,
    offsetHeight: PropTypes.number,
    onClick: PropTypes.func,
    CenterLeftClick: PropTypes.func,
    CenterRightClick: PropTypes.func,
    RightButtonClick: PropTypes.func,
    RightButtonClick1: PropTypes.func,
    RightButtonClick2: PropTypes.func,
    LeftDataClick: PropTypes.func,
    LeftData: PropTypes.array,
};

PageSubTitle.defaultProps = {
    style: {},
    subTitle: '',
    CenterContentText1: '',
    RightButtonText1: '',
    RightButtonText2: '',
    RightTextfeild: '',
    isborderShow: true,
    isCenterButtonShow: true,
    isCenterArrowButtonShow: true,
    isRightButtonShow: true,
    IsSticky: true,
    offsetHeight: 50,
    isRightButton1: true,
    RightButtonDisabled1: false,
    isRightButton2: true,
    LeftData: [{
        icon: Images.Play_IC,
        text: 'Tutorial',
        tooltip: 'Tutorial',
        isDrop: false,
        ShowTutorial: true
    },
    {
        icon: Images.LayerIcon,
        text: 'Campain Add',
        tooltip: 'Campain Add',
        isDrop: false,
        ShowTutorial: false
    },
    {
        icon: Images.Viewicon,
        text: 'Art Work Info',
        tooltip: 'Art Work Info',
        isDrop: true,
        ShowTutorial: false
    }],
    onClick: () => { },
    CenterLeftClick: () => { },
    CenterRightClick: () => { },
    RightButtonClick1: () => { },
    RightButtonClick2: () => { },
    LeftDataClick: () => { },
}

export default PageSubTitle;
