import React, { useEffect, useRef, useState } from 'react';
import { MyiFrame } from '../../components';
import { IsEmpty, getItemStore, storageKeys } from '../../config/appConstant';
import { Images } from '../../config';

const PreviewPost = (props) => {
   useEffect(() => {

   }, []);

   const isVideoCheck = (url) => {
      if (IsEmpty(url)) {
         return false
      } else {
         return /\.mp4($|\?)/i.test(url);
      }
   };

   const isVideoHTML = isVideoCheck(props.HTMLImageURL);
   const HTMLVideoURL = "https://www.mybauschreach.com/Facebook_ads_2023/Assets/Multibrand_Facebook_Ad1_23102023.mp4"
   const HTMLImageURL = IsEmpty(props.HTMLImageURL) ? Images.Noimage : props.HTMLImageURL
   const HTMLPostCopy = IsEmpty(props.HTMLPostCopy) ? "" : props.HTMLPostCopy

   const htmlContent = `
    <!DOCTYPE html>
<html lang="en">
   <head>
      <title>Bausch + Lomb</title>
      <meta name="ad.size" content="width=300,height=600">
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <meta name="description" content="" />
      <style>
         @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
         * {
         margin: 0;
         padding: 0;
         box-sizing: border-box;
         font-family: 'Roboto', sans-serif;
         }
         ::-webkit-scrollbar {
         display: none;
         }
         img {
         border: 0;
         vertical-align: middle;
         }
         sup {
         font-size: 60%;
         }
         .banner-box {
         padding: 55px 0px 100px 10px;
         box-sizing: border-box;
         position: relative;
         width: 280px;
         height: 580px;
         overflow: hidden;
         border: transparent;
         color: #fff;
         font-family: 'Roboto', sans-serif;
         font-weight: 300;
         background: #ffffff url('https://www.mybauschreach.com/Facebook_ads_2023/Assets/Iphone_bg_new1.png') no-repeat;
         background-size: cover;
         background-position: -2px 0;
         }
         .all_frame {
         left: 0;
         top: 0px;
         width: 96.2%;
         height: 100%;
         position: relative;
         }
         .banner_main {
         width: 100%;
         padding: 0px 15px;
         margin: 0px 0 10px 0;
         display: flex;
         align-items: center;
         justify-content: flex-start;
         }
         .banner_main .logo {
         border-radius: 100%;
         overflow: hidden;
         max-width: 30px;
         }
         .banner_main .logo img {
         width: 100%;
         height:100%
         }
         .text1 {
         width: 100%;
         }
         .banner_content {
         padding-left: 15px;
         }
         .text1 div {
         font-size: 15px;
         font-weight: bold;
         color: #141414;
         }
         .small_text div {
         font-size: 12px;
         font-weight: 400;
         color: #636466;
         }
         .sub_text {
         padding: 0px 5px;
         max-height: 128px;
         min-height: 15px;
         overflow-y: auto;
         }
         .sub_text div {
         font-size: 13px;
         line-height: 1.2;
         font-weight: 400;
         color: #000000;
         margin-bottom: 5px;
         }
         .btn {
         width: 75px;
         height: 30px;
         text-align: center;
         border: 2px solid #636466;
         border-radius: 4px;
         }
         .btn div {
         font-size: 11px;
         color: #636466;
         font-weight: 700;
         text-align: center;
         width: 100%;
         line-height: 30px;
         }
       .video_sec {
         background-origin: initial;
         background-repeat: no-repeat;
         background-size: cover;
         overflow: hidden;
         position: relative;
         display: flex;
         height: 300px;
         width: 260px;
         justify-content: center;
         align-items: center; /* Add this to center the content */
      }

      .video_sec video,
      .video_sec img {
         display: block;
         max-height: 100%;
         max-width: 100%;
         width: 100%;
         height: 100%;
         object-fit: contain; /* Ensure the image fits within the div without distortion */
         position: relative;
      }
         .footer {
         position: relative;
         left: 0;
         bottom: 0;
         width: 100%;
         height: 50px;
         background-color: #efefef;
         display: flex;
         justify-content: space-between;
         align-items: center;
         padding: 0 10px;
         box-sizing: border-box;
         border-bottom-left-radius: 5px 10px;
         border-bottom-right-radius: 5px 10px;
         }
         .text2 div {
         font-size: 9px;
         line-height: 1;
         font-weight: bold;
         color: #141414;
         }
         .footer_small_text div {
         font-size: 11px;
         line-height: 1.3;
         font-weight: 400;
         color: #636466;
         }
         .avialable {
         padding-top: 10px;
         }
      </style>
   </head>
   <body>
      <div class="banner-box">
         <div class="all_frame">
            <div class="banner_main">
               <div class="logo">
                  <img src="https://w7.pngwing.com/pngs/1024/475/png-transparent-circle-angle-material-gray-circle-pattern-border-texture-white.png"
                     alt="" title="" />
               </div>
               <div class="banner_content">
                  <div class="text1">
                     <div>${getItemStore(storageKeys.PracticeName)}</div>
                  </div>
               </div>
            </div>
            <div class="sub_text">
               <div>${HTMLPostCopy}</div>
            </div>
            <div class="video_sec">
               ${isVideoHTML ?
         `<video playsinline autoplay muted controls id="vid" src="${HTMLImageURL}"/>`
         : `<img src="${HTMLImageURL}" alt="Dynamic Media"/>`}
            </div>
         </div>
      </div>
   </body>
   <script>
      document.getElementById('vid').play();
   </script>
</html>
 
  `;

   return (<MyiFrame HTMLContent={htmlContent} />)

}

export default PreviewPost;
