
import React, { useEffect, useState } from 'react';
import './ProgressScroll.css';
import PropTypes from "prop-types";

const ProgressBar = (props) => {
    const { Progress } = props
    const [progress, setProgress] = useState(Progress);




    useEffect(() => {
        setProgress(Progress)
    }, [Progress])

    return (
        <>
            <div style={{ width: '0px' }}>
                <div className="dot-progress-bar">
                    <div className={`dot ${progress >= 20 && 'active'}`}></div>
                    <div className={`dot ${progress >= 40 && 'active'}`}></div>
                    <div className={`dot ${progress >= 60 && 'active'}`}></div>
                    <div className={`dot ${progress >= 80 && 'active'}`}></div>
                    {/* <div className={`dot ${progress >= 100 && 'active'}`}></div> */}
                    <div className="line" style={{ height: `${progress + 2.8}%` }}></div>
                    {/* <div className="line" style={{ height: `${progress == 20 ? progress - 0 : progress == 40 ? progress - 3 : progress == 60 ? progress - 6 : progress == 80 ? progress - 9 : progress}%` }}></div> */}
                </div>
            </div>
        </>
    );
};
ProgressBar.propTypes = {
    customClass: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    UpdateProgress: PropTypes.func,
    Progress: PropTypes.number,
};

ProgressBar.defaultProps = {
    customClass: {},
    Progress: false,
    UpdateProgress: () => { },

};
export default ProgressBar;

