import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import CircularProgress from "../Loading";

export default class MyiFrame extends Component {
    constructor() {
        super();
        this.state = {
            iFrameHeight: '0px',
            iFrameWidth: '0px',
            isLoading: true
        }
    }

    render() {
        return (
            <>
                <iframe
                    style={{ overflow: 'visible' }}
                    id='iframe'
                    onLoad={() => {
                        const obj = ReactDOM.findDOMNode(this);
                        this.props.src != undefined ?
                            this.setState({
                                "iFrameHeight": this.props.Height,
                                "iFrameWidth": this.props.Width,
                            }) :
                            this.setState({
                                "iFrameHeight": obj.contentWindow.document.body.scrollHeight + 8 + 'px',
                                "iFrameWidth": obj.contentWindow.document.body.scrollWidth + 'px',
                            });

                        this.setState({ isLoading: false });
                    }}
                    src={this.props.src}
                    title="iFrame"
                    srcDoc={this.props.HTMLContent}
                    width={this.props.isFacbook ? this.props.Width : '100%'}
                    height={this.props.isFacbook ? this.props.Height : (this.state.iFrameHeight == '8px' ? this.props.Height : this.state.iFrameHeight)}
                    scrolling="no"
                    frameBorder="0"
                    allow='autoplay; encrypted-media'
                />
                {this.state.isLoading ?
                    <div style={{ position: 'absolute', display: 'flex', justifySelf: 'center' }}>
                        <CircularProgress />
                    </div> : null}
            </>
        );
    }
}