import React, { useState } from 'react';
import * as APIController from '../../API/APIController';
import { clsDownloadFile } from '../../API/APIParameters';
import { Button, MyiFrame, Text } from "../../components";
import Notification from '../../components/Notification/Notification';
import { Images } from '../../config';
import { Constanttitle, IsEmpty } from "../../config/appConstant";
import "./ViewSocialPost.css";
import PreviewPost from '../AddSocialPost/PreviewPost';

const ViewSocialPost = (props) => {
    const [downloadloading, setdownloadloading] = useState(false);
    const ContentAllData = props.ContentAllData;

    const downloadAsset = async (FileURL, Name, ProductAssetID) => {
        setdownloadloading(true)
        const image = await fetch(FileURL);
        const imageBlog = await image.blob()
        const href = window.URL.createObjectURL(imageBlog);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', Name); //or any other extension
        document.body.appendChild(link);
        link.click();

        wsGetDownloadFile(FileURL, ProductAssetID)
    };

    const wsGetDownloadFile = (FileURL, ProductAssetID) => {
        const objParameter = new clsDownloadFile();
        objParameter.FileURL = FileURL;
        objParameter.ProductAssetID = ProductAssetID;

        APIController.GetDownloadFile(objParameter)
            .then((response) => {
                if (response.error == null) {
                    setdownloadloading(false)
                } else {
                }
            })
    }
    const CopyToClipboard = async (DescriptionText, Subcategory) => {
        if (!IsEmpty(DescriptionText)) {
            navigator.clipboard.writeText(DescriptionText)
            handleNotification(true, Constanttitle.CopiedClipboard, false);
        }
    }

    const [ShowNotification, setShowNotification] = useState(false);
    const [ToastType, setToastType] = useState('');
    const [Message, setMessage] = useState('')
    const handleNotification = (val, msg, type) => {
        setShowNotification(val);
        setMessage(msg)
        setToastType(type);
    }
    return (
        <>
            {ShowNotification && <Notification handleClose={handleNotification.bind(this)} message={Message} variant={ToastType} />}

            {ContentAllData.map((item, ind) => {
                const DescriptionText = !IsEmpty(item.Description) ? item.Description.replace(/(<([^>]+)>)/gi, "") : null;
                return <>
                    <div className={IsEmpty(item.Description) ? 'view-post-main view-post-main-boder' : 'view-post-main-text view-post-main-boder'} >
                        {!IsEmpty(DescriptionText) &&
                            <div className='viewsocial-left-section' style={!IsEmpty(DescriptionText) ? { width: "65%" } : { width: "100%" }}>
                                <div className='left-parapraph'>
                                    {/* <Text medium head fontgreendark>{DescriptionText}</Text> */}
                                    <div className="view-post-description" dangerouslySetInnerHTML={{ __html: item.Description }} />
                                </div>
                                <div className='left-bottom'>
                                    <hr />
                                    {props.popupType == "Preview" ?
                                        <div className='text-time'>
                                            <Text medium body2 fontgreendark>Post Time: 8:00am CST</Text>
                                            <div className='icons'>
                                                {item.IsLeadGenPost == 1 && item.IsLeadGenPost == true && <img className='facebook-icon' src={Images.lead_gen_ad} />}
                                                {item.PostToFacebook == 1 && item.PostToFacebook == true && <img className='facebook-icon' src={Images.Facebook_IC} />}
                                                {item.PostToInstagram == 1 && item.PostToInstagram == true && <img className='instagram-icon' src={Images.Instagram_IC} />}
                                            </div>
                                        </div> : <></>}
                                    {props.popupType == "Edit"
                                        ? <div className='left-end-button'>
                                            <div><Button onClick={() => props.handlePopup(true, "Reschedule", "Reschedule Post", [item])} type={3} round title='Reschedule' /></div>
                                            <div><Button type={3} round title='Delete' onClick={() => props.handleDeletePost(item.ID, item.IsPersonalAsset)} /></div>
                                        </div>
                                        : props.popupType == "View Post" &&
                                        <div className='left-end-button'>
                                            <div><Button disabled={downloadloading} onClick={() => downloadAsset(item.ImageURL, item.Name, item.ProductAssetID)} type={3} round title='DOWNLOAD' /></div>
                                            <div><Button onClick={(e) => CopyToClipboard(DescriptionText)} type={3} round title='COPY TEXT' /></div>
                                        </div>
                                    }
                                </div>
                            </div>}
                        <div style={!IsEmpty(DescriptionText) ? { minWidth: '35%' } : {}}>
                            {IsEmpty(item.ImageURL)
                                ? <div> <Text semibold callout>Preview Data Not Found</Text> </div>
                                : item.ImageURL.indexOf('.zip') != -1 ?
                                    <div style={{ textAlign: 'center' }}><h5>This is .zip File, Please download and open</h5></div>
                                    : item.ImageURL.indexOf('.pdf') != -1
                                        ? <MyiFrame isFacbook={true} Height={'600px'} Width={'1250px'} src={item.ImageURL} />
                                        : !IsEmpty(DescriptionText)
                                            ? <PreviewPost HTMLPostCopy={item.Description} HTMLImageURL={item.ImageURL} />
                                            : item.ImageURL.indexOf('.mp4') != -1
                                                ? <video controls width="700px" height="450px" autoPlay >
                                                    <source src={item.ImageURL} type="video/mp4" />
                                                </video>
                                                : item.ImageURL.indexOf('.gif') != -1
                                                    ? <img src={item.ImageURL} alt="place" style={{ height: '550px' }} />
                                                    : <img src={item.ImageURL} alt="img" style={{ width: "100%" }} />

                            }
                        </div>
                    </div>
                    {IsEmpty(DescriptionText) &&
                        <div style={{ marginTop: '10px', textAlign: props.IsDownloadBtnCenter ? 'center' : 'lef' }}>
                            <Button disabled={downloadloading} onClick={() => downloadAsset(item.ImageURL, item.Name, item.ProductAssetID)} type={3} round title='DOWNLOAD' />
                        </div>}
                    <hr />
                </>
            })}
        </>
    );
}

export default ViewSocialPost;


