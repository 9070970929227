import React from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

const SimplyTextfIeld = ({ label, value, onChange, maxLength, disabled, required }) => {
    return (
        <TextField
            label={label}
            value={value}
            onChange={onChange}
            inputProps={{ maxLength }}
            disabled={disabled}
            required={required}
            fullWidth
            variant="outlined"
            margin="normal"
        />
    );
}

SimplyTextfIeld.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    maxLength: PropTypes.number,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
};

SimplyTextfIeld.defaultProps = {
    label: '',
    value: '',
    onChange: () => {},
    maxLength: 150,
    disabled: false,
    required: false,
};

export default SimplyTextfIeld;
