import { Button, styled } from '@material-ui/core';
import * as React from 'react';
import { BaseColor } from '../../config';
import ButtonStyle from '../Button/Buttontyle';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


const InputFileUpload = props => {
    const classes = ButtonStyle();
    const { title, type, accept, full, endIcon, onChange, small, extraSmall, customClass, round, startIcon, disabled, variant, loading } = props;

    return (
        <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            className={`${classes.default} ${customClass} ${round && classes.round} ${small && classes.small} ${extraSmall && classes.extraSmall} ${variant === 'contained' ? classes.textDefaultContained : classes.textDefaultOutline}`}
            style={{ width: '130px', backgroundColor: variant == 'outlined' ? '' : type === 1 ? BaseColor.mainButtonColor : type === 2 ? BaseColor.SecoundButtonColor : BaseColor.ThirdButtonColor }}
        //   startIcon={<CloudUploadIcon />
        // }
        >
            {title}
            <VisuallyHiddenInput onChange={onChange} id="img" name="img" type="file" accept={accept} />
        </Button>
    );

}
InputFileUpload.defaultProps = {
    customClass: {},
    startIcon: null,
    variant: 'contained',
    full: false,
    round: false,
    type: 1,
    small: false,
    extraSmall: false,
    loading: false,
};
export default InputFileUpload;