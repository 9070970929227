

import { BaseColor } from "../../config";

export default {
  pageMain: {
    display: 'flex',
    alignItems: 'center',
    margin: '0px 5%',
    padding: '5px 0px',
    border: '0',
  },
  
  HomeIcon: {
    height: 48,
    cursor: 'pointer',
    padding: '12px 20px 12px 0px',
  },
  borderbotttom: {
    display: 'flex',
    alignItems: 'center',
    margin: '0px 54px',
    padding: '5px 0px',
    // borderBottom: '1px solid',
    // borderBottomColor: BaseColor.dividerColor
  }
}