import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { BrowserRouter as Router } from "react-router-dom";
import { MyProvider } from './MyProvider';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './htmlBuilder/getConfiguration/theme.ts';

// Conditionally suppress console errors and warnings in development
if (process.env.NODE_ENV === 'development') {
  console.error = () => { };
  console.warn = () => { };
}
ReactDOM.createRoot(document.getElementById('root')).render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <MyProvider>
      <Router>
        <App />
      </Router>
    </MyProvider>
  </ThemeProvider>


);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
