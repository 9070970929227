import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import * as APIController from '../../API/APIController';
import { clsAddEditSubscription, clsGetMyCalendar } from '../../API/APIParameters';

import Notification from '../Notification/Notification';
import CommonModal from '../modal/CommonModal';
import { Images } from '../../config';
import { GetDateWithCommonFormat, GetDateWithSingleDatePiker, IsEmpty, getItemStore, storageKeys } from '../../config/appConstant';
import PageSubTitle from '../PageSubTitle/PageSubTitle';
import './CustomCalender.css';
import Style from "./CustomCalenderStyle";
import { Button, CommonLoader } from '../../components';
import ViewSocialPost from '../../screens/ViewSocialPost/ViewSocialPost';
import ScheduleAutomationcalenderForm from '../../screens/AdminScreen/Automation/ViewAutomationCalender/ScheduleAutomationcalenderForm';


const CustomCalenderForAdmin = (props) => {
    const { showHeader = true, classes } = props; // Destructure showHeader prop with default value true

    const SubscriptionID = getItemStore(storageKeys.SubscriptionID)
    const LocationID = getItemStore(storageKeys.LocationIDs)
    const DAY = new Date();
    const MonthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const [MonthData, setWeekDay] = React.useState([{ "ID": 1, "Name": "Sunday" }, { "ID": 2, "Name": "Monday" }, { "ID": 3, "Name": "Tuesday" }, { "ID": 4, "Name": "Wednesday" }, { "ID": 5, "Name": "Thursday" }, { "ID": 6, "Name": "Friday" }, { "ID": 7, "Name": "Saturday" }]);

    const [AllCalenderData, setAllCalenderData] = React.useState([])
    const [IsLoading, setIsLoading] = React.useState(true);
    const [CurrentMonth, setCurrentMonth] = React.useState(props.CalendarMonth == 0 ? 0 : !IsEmpty(props.CalendarMonth) ? props.CalendarMonth : DAY.getMonth() + 1);
    const [CurrentYear, setCurrentYear] = React.useState(!IsEmpty(props.CurrentYear) ? props.CurrentYear : DAY.getFullYear());
    const [CurrentMonthName, setCurrentMonthName] = React.useState(MonthNames[CurrentMonth]);
    const [TotalDayOfMonth, setTotalDayOfMonth] = React.useState([]);
    const [TotalCards, setTotalCards] = React.useState([]);
    const [firstDateWeek, setfirstDateWeek] = React.useState(new Date(DAY.getFullYear(), DAY.getMonth(), 1).getDay() + 1);
    const [BlurCards, setBlurCards] = React.useState(0);// for Card Color Blure purpose
    const [AssetArray, setAssetArray] = React.useState([]);// for API Pass purpose
    const [ProductTutorialLink, setProductTutorialLink] = useState("");

    const [AssetDate, setAssetDate] = useState(GetDateWithCommonFormat(new Date()));
    const [ContentAllData, setContentAllData] = useState({});

    const [OpenPopup, setOpenPopup] = useState(false)
    const [DeleteIndex, setDeleteIndex] = useState("");

    const [InitialState, setInitialState] = useState({ PopupTitle: "", PopupActionText: "", open: "", Action: "", EditID: "", loading: false });

    let MonthName = MonthNames[DAY.getMonth()];

    useEffect(() => {
        GetCurrntMonth(CurrentMonth, CurrentYear)
    }, [])

    useEffect(() => {
        if (props.ChanngeProps == 1) {
            OnPressNext()
            props.handleChangeProps(-1, (MonthNames[CurrentMonth]), CurrentYear)
        } else if (props.ChanngeProps == 2) {
            OnPressPrevious()
            props.handleChangeProps(-1, (MonthNames[CurrentMonth]), CurrentYear)
        }
    }, [props?.ChanngeProps])

    const GetCurrntMonth = (CurrentMonth, CurrentYear) => {
        setIsLoading(true)
        setCurrentMonthName(MonthNames[CurrentMonth - 1])
        var pairs = [];
        for (let i = 0; i < new Date(CurrentYear, CurrentMonth, 0).getDate(); i++) {
            pairs.push({ Date: i + 1 });
        }
        //Logic for Get TodalDays Of month from start Day of week to totalday
        var cards = [];
        let firstDay = new Date(CurrentYear, CurrentMonth - 1, 1);
        let lastDay = new Date(CurrentYear, CurrentMonth, 0);
        let StartDayofWeek = MonthData[firstDay.getDay()].Name
        let Indexxx = MonthData.findIndex(obj => obj.Name === StartDayofWeek);
        let TotalDaysOfMonth = pairs.length + Indexxx
        setfirstDateWeek(firstDay.getDay() + 1)

        for (let i = 0; i < TotalDaysOfMonth; i++) {
            // new Date().getDate() == TotalCards[i].Date
            cards.push({ ID: i + 1, PreviewURL: 'https://cdn.mybauschreach.com/Images/AutomationAssets/30ad2c7-88d2-5f5-83f3-44706c55bfbc_thumb.png?v=1698642914' });
        }
        setTotalCards(cards)
        setBlurCards(Indexxx)
        setTotalDayOfMonth(pairs)
        wsGetMyCalendar(CurrentMonth, CurrentYear)

        //-------------------------------------------------------------------
        // { val != 'First' && props.handleCalendar(GetDateWithMyFormat(firstDay), GetDateWithMyFormat(lastDay)) }//fetch date from Api -Call Api
    }

    const wsGetMyCalendar = (CurrentMonth, CurrentYear) => {
        const objParameter = new clsGetMyCalendar();
        objParameter.ID = 0;
        objParameter.Month = CurrentMonth;
        objParameter.Year = CurrentYear;

        APIController.GetMyCalendar(objParameter)
            .then((response) => {
                if (response.error == null) {
                    setAllCalenderData(response.data)
                }
                setIsLoading(false)
            })
    }

    const nextMonth = (date, currentMonth, currentYear) => {
        setIsLoading(true)
        setCurrentMonth(currentMonth + 1)
        var current = ''
        if (currentMonth == 11) {
            current = new Date(date.getFullYear() + 1, -1, 1);
            setfirstDateWeek(current.getDay() + 1)
            setCurrentMonthName(MonthNames[current.getMonth()])
            setCurrentYear(current.getFullYear())
        } else {
            current = new Date(date.getFullYear(), currentMonth, 1);
            setfirstDateWeek(current.getDay() + 1)
            setCurrentMonthName(MonthNames[current.getMonth()])
            setCurrentYear(current.getFullYear())
        }
        var daysInMonth = new Date(current.getFullYear(), current.getMonth() + 1, 0).getDate();
        var pairs = [];
        for (let i = 0; i < daysInMonth; i++) {
            pairs.push({ Date: i + 1 });
        }

        //Logic for Get TodalDays Of month from start Day of week to totalday
        var cards = [];
        let firstDay = new Date(current.getFullYear(), current.getMonth(), 1);
        let lastDay = new Date(current.getFullYear(), current.getMonth() + 1, 0);
        let StartDayofWeek = MonthData[firstDay.getDay()].Name
        let Indexxx = MonthData.findIndex(obj => obj.Name === StartDayofWeek);
        let TotalDaysOfMonth = pairs.length + Indexxx
        for (let i = 0; i < TotalDaysOfMonth; i++) {
            cards.push({ ID: i + 1 });
        }
        setTotalCards(cards)
        setBlurCards(Indexxx)
        //-----------------------------------------------------------------
        // props.handleCalendar(GetCMMSDateWithMyFormat(firstDay), GetCMMSDateWithMyFormat(lastDay))//fetch date from Api -Call Api
        setTotalDayOfMonth(pairs)
        props.GetFilterMonthData(current.getMonth() + 1, current.getFullYear())
        // setIsLoading(false)
    }

    const previousMonth = (date, currentMonth, currentYear) => {
        setIsLoading(true)
        setCurrentMonth(currentMonth - 1)
        var current = ''
        if (currentMonth == 1) {
            current = new Date(date.getFullYear(), -1, 1);
            setfirstDateWeek(current.getDay() + 1)
            setCurrentMonthName(MonthNames[current.getMonth()])
            setCurrentYear(current.getFullYear())
        } else {
            current = new Date(date.getFullYear(), currentMonth - 2, 1);
            setfirstDateWeek(current.getDay() + 1)
            setCurrentMonthName(MonthNames[current.getMonth()])
            setCurrentYear(current.getFullYear())
        }

        var daysInMonth = new Date(current.getFullYear(), current.getMonth() + 1, 0).getDate();
        var pairs = [];
        for (let i = 0; i < daysInMonth; i++) {
            pairs.push({ Date: i + 1 });
        }

        //Logic for Get TodalDays Of month from start Day of week to totalday
        var cards = [];
        let firstDay = new Date(current.getFullYear(), current.getMonth(), 1);
        let lastDay = new Date(current.getFullYear(), current.getMonth() + 1, 0);
        let StartDayofWeek = MonthData[firstDay.getDay()].Name
        let Indexxx = MonthData.findIndex(obj => obj.Name === StartDayofWeek);
        let TotalDaysOfMonth = pairs.length + Indexxx
        for (let i = 0; i < TotalDaysOfMonth; i++) {
            cards.push({ ID: i + 1 });
        }
        setTotalCards(cards)
        setBlurCards(Indexxx)
        //--------------------------------------------------------------------
        // props.handleCalendar(GetCMMSDateWithMyFormat(firstDay), GetCMMSDateWithMyFormat(lastDay))//fetch date from Api -Call Api
        setTotalDayOfMonth(pairs)
        props.GetFilterMonthData(current.getMonth() + 1, current.getFullYear())
        setIsLoading(false)
    }

    const OnPressNext = () => {
        const currentYear = new Date().getFullYear();
        if (CurrentYear == currentYear && CurrentMonth < 12) {
            nextMonth(new Date(), CurrentMonth, CurrentYear);
        }
    }
    const OnPressPrevious = () => {
        const currentYear = new Date().getFullYear();
        if (CurrentYear == currentYear && CurrentMonth > 1) {
            previousMonth(new Date(), CurrentMonth, CurrentYear)
        }
    }


    const transformDeleteData = (specificIndex) => {
        const newArray = [...AllCalenderData];
        const assetsArray = newArray[specificIndex].Assets;
        assetsArray.forEach(asset => {
            asset.Status = 3;
        });
        newArray[specificIndex].Assets = assetsArray;

        setAllCalenderData(newArray)
        setOpenPopup(false)
    }

    const handlePopup = (isOpen, popupType, popupTitle, dataContent, AssetDate) => {
        // handlePopup EditID: EditID
        setInitialState({ ...InitialState, PopupTitle: popupTitle, PopupActionText: 'Add', Action: popupType, open: true, })
        if (popupType == 1) {
            let ContentData = []
            if (!IsEmpty(dataContent)) {
                dataContent.map((item) =>
                    ContentData.push({
                        ID: item.ID,
                        ProductAssetID: item.CategoryID,
                        Name: item.Name,
                        Description: item.Description,
                        ImageURL: item.ImageURL,
                        PreviewURL: item.PreviewURL,
                        PostToFacebook: item.PostToFacebook,
                        PostToInstagram: item.PostToInstagram,
                        AssetDate: GetDateWithCommonFormat(item.AssetDate),
                        PostTime: item.PostTime,
                        IsPersonalAsset: item.IsPersonalAsset
                    })
                )
            } else {
                setAssetDate(GetDateWithCommonFormat(AssetDate))
            }
            setContentAllData(ContentData)
        } else if (popupType == 3) {
            <ScheduleAutomationcalenderForm
                EditID={0}
                CalendarID={InitialState.EditID}
                CalendarYear={InitialState.FullElement.CalendarYear}
                CalendarMonth={InitialState.FullElement.CalendarMonth}
                onClose={handleClose.bind(this)}
                // handlebind={handlebind.bind(this)}
                handleNotification={handleNotification.bind(this)} />
        }

        setOpenPopup(isOpen)
    }

    const handleDeletePopup = (index, item) => {
        // !IsEmpty(item.Assets) && item.Assets.map((itm) => { })
        const hasPersonalAsset = item.Assets.some(item => item.IsPersonalAsset === 1);
        if (hasPersonalAsset) {
            handleNotification(true, "Delete", 'success')
        } else {
            setDeleteIndex(index)
        }
        setOpenPopup(true)
    }

    const handleDelete = () => {
        transformDeleteData(DeleteIndex)
    }
    const handleClose = () => {
        setOpenPopup(false)
    }

    const LeftDataClick = (e, index) => {
        if (index == 0 && !IsEmpty(ProductTutorialLink)) {
            window.open(ProductTutorialLink)
        }
    }

    const [ShowNotification, setShowNotification] = useState(false);
    const [Message, setMessage] = useState('');
    const [ToastType, setToastType] = useState('');
    const handleNotification = (val, msg, type) => {
        setShowNotification(val);
        setMessage(msg)
        setToastType(type);
    }

    const isFutureDate = (DateValue, AddDays) => {
        if (AddDays == "" || AddDays == undefined || AddDays == null) {
            {/* Add 10 Days To Add Post  */ }
            return new Date().getTime() > new Date(DateValue).getTime()
        } else {
            return new Date(new Date().getTime() + (AddDays * 24 * 60 * 60 * 1000)) < new Date(DateValue).getTime()
        }
    }

    return (IsLoading ? <CommonLoader /> :
        <>
            <div className='customcalender-daynamecontent' >
                <div className='customcalender-dayname' >
                    {MonthData.map((Item, value) => {
                        return (
                            <div key={value} id={'CalenderTopTitleContentView-day'} className={classes.CalenderTopTitleContentView} >{Item.Name}</div>
                        )
                    })}
                </div>
                <div className='customcalender-date'>
                    {TotalCards.map((item, index) => {
                        const calenderContentId = index < BlurCards ? "calender-content-view-gray" : "calender-content-view-white"
                        return (
                            <div key={item.id} className={classes.CalenderContentView} id={calenderContentId} style={{ marginRight: "5px" }} >

                                {AllCalenderData?.map((Item, Index) => {
                                    let CalenderDate = Item.DateOfCreation.split('-')[2]
                                    let CalenderFullDate = GetDateWithSingleDatePiker(CalenderDate + "-" + CurrentMonthName + "-" + CurrentYear)
                                    return (
                                        Index == index - firstDateWeek + 1 &&
                                        <div key={Index}>
                                            <div className='customcalender-mapincss'>
                                                <div className={MonthName == CurrentMonthName && new Date().getDate() == CalenderDate ? classes.CalenderCurrentContent : classes.CalenderInnerContent}>{CalenderDate}</div>
                                                <div className='customcalender-empty-width'>
                                                    {Item.Assets.length && !(Item.Assets.filter(asset => asset.IsLeadGenPost == 1) && Item.Assets[0].Status != 3) ? <img style={{ height: '18px', marginRight: '5px' }} src={Images.lead_gen_ad} /> : <></>}
                                                    {Item.Assets.length > 1 && Item.Assets[0].Status != 3 && <img style={{ height: '20px' }} src={Images.multiple_posts} />}
                                                </div>
                                            </div>
                                            <Divider className='customcalender-divider' />
                                            {CalenderFullDate == Item.DateOfCreation && Item.Assets != '' &&
                                                <div className={'content-box-calendar'}>
                                                    <div className={'box-image-calendar calendar-image-box'}
                                                        style={{ backgroundImage: `url(${!IsEmpty(Item.Assets[0]) && Item.Assets[0].PreviewURL})` }}>
                                                        <div className='button-hover'>
                                                            <div className='button-field-calendar'><Button round Medium type={3} title='Preview' onClick={() => handlePopup(true, 1, "View Social Post", Item.Assets)} /></div>
                                                            {!isFutureDate(CalenderFullDate) && <div className='button-field-calendar'><Button round Medium type={3} title='Edit' onClick={() => props.handleOpen('schedule', { ID: props.EditID, AssetID: Item.Assets[0].ID, CalendarYear: CurrentYear, CalendarMonth: CurrentMonth })} /></div>}
                                                            {!isFutureDate(CalenderFullDate) && <div className='button-field-calendar'><Button round Medium type={3} title='Delete' onClick={() => { handleDeletePopup(Index, Item) }} /></div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                        </div>)
                                })}
                            </div>)
                    })}
                </div>
            </div>

            {/* PopupTitle: "", PopupActionText: "", open: "", Action: "", EditID: "", loading: false */}
            {OpenPopup && <CommonModal
                maxWidth={InitialState.Action == 2 ? 'sm' : 'lg'}
                Title={InitialState.PopupTitle}
                ActionText={InitialState.PopupActionText}
                IsDeletePopup={InitialState.Action == 2 ? true : false}
                onClose={handleClose.bind(this)}
                onSave={handleDelete.bind(this)} IsScroll >
                <ViewSocialPost popupType={"Preview"} ContentAllData={ContentAllData} />
            </CommonModal>}

            {ShowNotification &&
                <Notification
                    handleClose={handleNotification.bind(this)}
                    message={Message}
                    variant={ToastType}
                />}
        </>
    )
}

export default withStyles(Style)(CustomCalenderForAdmin);

// onClick={() => handlePopup(true, "Edit", "Reschedule Post", Item.Assets)}